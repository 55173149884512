<template>
  <div style="width: 100%;">
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-row>
                <v-col class='d-flex flex-row align-center'>
                  <h1>Shopmonkey</h1>
                  <v-progress-circular
                      indeterminate
                      color="green"
                      v-if="loader"
                      style="margin-left: 10px;"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="syncInventory">Sync Inventory</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  export default {
    data () {
      return {
        loader: false,
        max25chars: v => v.length <= 25 || 'Input too long!',
        editMode: false,
      }
    },
    async mounted(){

    },
    computed: {
      ...mapGetters(['getEndpoint'])
    },
    methods: {
      async syncInventory(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/shopmonkey/syncInventory`)
          console.log(res.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      }
    }
  }
</script>
